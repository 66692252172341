import { useEffect, useState } from "react";
import { HORIZONTAL_VIDEO_PLAYER_URL } from "../../config/config";
import { Button } from "../button";
import { DisplayContent } from "../displayContent"
import { Document } from '@contentful/rich-text-types';

interface BannerProps {
  bannerImage?: string;
  bannerTitle?: string;
  bannerPhoneNumber?: string;
  exploreScrollContent?: Document;
  exploreScrollHeading?: string;
  headingPosition?: string;
  exploreLinkText?: string;
  exploreLinkUrl?: string;
  isProductBanner?: string;
  backgroundVideoId?: string;
  bannerSubTitle?: string;
  primaryLinkText?: string;
  primaryLinkUrl?: string;
  secondaryLinkText?: string;
  secondaryLinkVideoId?: string;
}
type exploreFunction = () => void;
interface Props {
  banner?: BannerProps,
  onClickExplore?: exploreFunction,
  exploreRef?: any
}

export const Banner = ({ banner, onClickExplore, exploreRef }: Props) => {

  const[watchVideo, setWatchVideo] = useState(false)
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  //function to make position css dynamc as per value coming from contentful
  const textPosition = (position: string) => {
    let displayCss;
    switch (position) {
      case 'Center': displayCss = 'text-center'
        break;
      case 'Left': displayCss = 'text-left'
        break;
      default:
        break;
    }
    return displayCss;
  }

  return (
    <>
    {watchVideo && <>
      <div className="absolute top-28 right-0 mr-2 mt-2 cursor-pointer z-50" onClick={()=>setWatchVideo(false)}><svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="14.5" cy="14.5" r="14.5" fill="white"></circle><path d="M8 8L20.9292 20.9292" stroke="#0057FF" stroke-width="2.36296"></path><path d="M21 8L8.07083 20.9292" stroke="#0057FF" stroke-width="2.36296"></path></svg></div>
      <div className="ipad:h-[501px] desk:h-[740px] w-[98vw]">
              <iframe
                src={
                  HORIZONTAL_VIDEO_PLAYER_URL +
                  banner?.secondaryLinkVideoId + '&autoplay&muted&loop'
                }
                allowFullScreen
                className=" w-full h-[56vw] absolute"
              ></iframe>
            </div>
            {/* {banner?.primaryLinkText &&
                  <div className="relative h-20 pt-6 flex justify-center">
                    <a href={banner?.primaryLinkUrl} target="_blank">
                      <button type="button" className=" text-blue bg-whiteText 
            font-semibold
            rounded-full text-lg px-8 py-2.5 text-center 
            me-2 mb-2 ">
                        {banner?.primaryLinkText}
                      </button></a>
                  </div>}*/}</>} 
      {banner?.backgroundVideoId && !watchVideo &&
      <>
      {/* desktop view for video banner */}
       {!isMobile && <div className={` absolute top-0 h-[83dvh]  max-h-[501px] w-full overflow-hidden `}>
          <div className="relative">
            <div className="h-[675px] w-[100vw]">
              <iframe
                src={
                  HORIZONTAL_VIDEO_PLAYER_URL +
                  banner?.backgroundVideoId + '&autoplay&muted&loop&controls=0'
                }
                allowFullScreen
                className=" w-full h-[56vw] absolute"
              ></iframe>
            </div>
          </div>
        </div>}
      {/* mobile view for video banner */}
        {isMobile && <div className={`absolute top-0 h-[68dvh] max-h-[501px] w-full overflow-hidden`}>
        <div className="relative">
          <div className="h-[68vh] tab:h-[83vh] w-[200vw]">
            <iframe
              src={
                HORIZONTAL_VIDEO_PLAYER_URL +
                banner?.backgroundVideoId + '&autoplay&muted&loop&controls=0'
              }
              allowFullScreen
              className=" w-full h-full relative max-h-[900px] object-cover"
            ></iframe>
          </div>
        </div>
        </div>}
        </>
      }
      {/* hero banner- contain banner image, title and phone number and explore arrow */}
      {/* desktop view with banner image */}
      { !watchVideo && ((!isMobile && banner?.isProductBanner === 'Yes') || (isMobile && banner?.backgroundVideoId) || banner?.isProductBanner !== 'Yes')  && (
      <div className={`${(banner?.isProductBanner === 'Yes' && !banner?.backgroundVideoId) ? 'productBanner h-product' : (banner?.backgroundVideoId?'w-full ipad:h-[38vh] desk:h-[68vh] h-[57vh]':'w-full h-screen')}`}>

        <div className={`${(banner?.isProductBanner === 'Yes' && !banner?.backgroundVideoId) ? 'productBanner' : 'w-full h-screen  relative'}`} >
          {banner?.bannerImage && (banner?.isProductBanner !== 'Yes') ? <img className="w-full h-screen block object-cover object-[top_center] origin-[100%_0]"
            src={banner?.bannerImage} alt="banner_image"></img>
            : ((banner?.isProductBanner==="Yes") && !banner?.backgroundVideoId && banner?.bannerImage) ?
              <div className="w-full flex justify-end pr-[10%]">
                <img
                  className={` w-[343px] md:w-[360px] lg:w-[496px] xl:w-[631px] 2xl:w-[796px] 
                   w-[343px]`}
                  src={banner?.bannerImage} alt="banner_image"></img>
              </div> : null}


          <div className={`absolute top-0 left-0 w-full h-full ${(banner?.isProductBanner === 'Yes') ? '' : 'bg-[rgba(0,0,0,0.5)]'}`}></div>
          <div className={`${(banner?.isProductBanner === 'Yes') ? 'flex justify-center   w-full' : ''}`}>
            <div className={`absolute  ${(banner?.isProductBanner === 'Yes') ? (!banner?.backgroundVideoId?'tab:max-w-[32%] top-[28%] left-48':' tab:max-w-[50%]'+ (isMobile? ' top-[16%]': ' ipad:top-20 desk:top-22')) : ' tab:max-w-[50%] font-[MarkPro-Light SKY] left-[5%] bottom-[21%] tab:left-[10%]'}`}>
              <h2 className="desk:text-[4.25rem] max-desk:text-[2.8125rem] max-desk:font-MarkLight  text-whiteText leading-none tracking-[normal]"><div>{banner?.bannerTitle}</div></h2>
              {banner?.backgroundVideoId && banner?.bannerImage &&
                <div className="flex justify-center">
                  <img
                    src={banner?.bannerImage} alt="banner_image"></img>
                </div>}
              {banner?.bannerSubTitle &&
                <h6 className="desk:text-[1rem] max-desk:text-[1rem] max-desk:font-MarkLight  text-whiteText leading-none tracking-[normal]"><div>{banner?.bannerSubTitle}</div></h6>}
              <h3 className={`desk:text-[3.125rem] mt-[1rem] max-desk:text-[2.1875rem] leading-none tracking-[normal] ${banner?.isProductBanner === "Yes"?"text-whiteText ":'text-headingYellow'} `}><div>{banner?.bannerPhoneNumber}</div></h3>
              <div className={`flex ${isMobile?'flex-col':''}`}>
                {banner?.secondaryLinkText &&
                  <div className="relative h-20 pt-6 w-full flex justify-center">
                    {/* <a href={ HORIZONTAL_VIDEO_PLAYER_URL +banner?.secondaryLinkVideoId}> */}
                      <button type="button" className=" text-whiteText bg-blue 
            font-semibold
            rounded-full text-lg px-8 py-2.5 text-center 
            me-2 mb-2 "
            onClick={()=>setWatchVideo(true)}>
                        {banner?.secondaryLinkText}
                      </button>
                      {/* </a> */}
                  </div>}
                {/* {banner?.primaryLinkText &&
                  <div className="relative h-20 pt-6">
                    <a href={banner?.primaryLinkUrl} target="_blank">
                      <button type="button" className=" text-blue bg-whiteText 
            font-semibold
            rounded-full text-lg px-8 py-2.5 text-center 
            me-2 mb-2 ">
                        {banner?.primaryLinkText}
                      </button></a>
                  </div>} */}
              </div>
              
            </div>
          </div>
          <div>
            {!banner?.secondaryLinkText &&
              <a href={banner?.exploreLinkUrl}onClick={onClickExplore} className="absolute ml-[50%] z-10 text-whiteText tracking-widest no-underline pt-[60px] transition-[opacity_0.3s] -translate-x-1/2 bottom-14 desk:bottom-[4.5rem] cursor-pointer hover:opacity-50 "
                data-testid="explore">{banner?.exploreLinkText}
                <span className="absolute top-0 left-1/2 w-6 h-6 -ml-3 border-l border-b border-l-whiteText -rotate-45 border-b-whiteText animate-arrow"></span>
              </a>
            }
          </div>

        </div>


      </div>
      )
      }
      {/* mobile view for product banner with image */}
      {isMobile && banner?.isProductBanner === 'Yes' && !banner?.backgroundVideoId &&
        <div className={`${(banner?.isProductBanner === 'Yes' && !banner?.backgroundVideoId) ? 'productBanner h-[94vh] ipad:h-product' : 'w-full h-screen'}`}>

          <div className={`${(banner?.isProductBanner === 'Yes' && !banner?.backgroundVideoId) ? 'productBanner' : 'w-full h-screen  relative'}`} >
            <div className={`absolute top-0 left-0 w-full h-full ${(banner?.isProductBanner === 'Yes') ? '' : 'bg-[rgba(0,0,0,0.5)]'}`}></div>
            <div className={`${(banner?.isProductBanner === 'Yes') ? 'flex justify-center   w-full' : ''}`}>
              <div className={`absolute  ${(banner?.isProductBanner === 'Yes') ? (!banner?.backgroundVideoId ? 'tab:max-w-[25%] top-[12%] left-6' : ' tab:max-w-[50%]' + (isMobile ? ' top-[20%]' : ' desk:top-22')) : ' tab:max-w-[50%] font-[MarkPro-Light SKY] left-[5%] bottom-[21%] tab:left-[10%]'}`}>
                <h2 className=" text-center desk:text-[4.25rem] max-desk:text-[2.8125rem] max-desk:font-MarkLight  text-whiteText leading-none tracking-[normal]"><div>{banner?.bannerTitle}</div></h2>

                {banner?.bannerSubTitle &&
                  <h6 className="text-center desk:text-[1rem] max-desk:text-[1rem] max-desk:font-MarkLight  text-whiteText leading-none tracking-[normal]"><div>{banner?.bannerSubTitle}</div></h6>}
                <h3 className="desk:text-[3.125rem] mt-[1rem] max-desk:text-[2.1875rem] leading-none tracking-[normal] text-whiteText text-center"><div>{banner?.bannerPhoneNumber}</div></h3>
                <div className={`flex ${isMobile ? 'flex-col' : 'flex-row'}`}>
                  {banner?.secondaryLinkText &&
                    <div className="relative h-20 pt-6 flex justify-center">
                      <button type="button" className=" text-whiteText bg-blue 
      font-semibold
      rounded-full text-lg px-8 py-2.5 text-center 
      me-2 mb-2 "
                        onClick={() => setWatchVideo(true)}>
                        {banner?.secondaryLinkText}
                      </button>
                    </div>}
                  {banner?.primaryLinkText &&
                    <div className="relative h-20 pt-6 flex justify-center">
                      <a href={banner?.primaryLinkUrl} target="_blank">
                        <button type="button" className=" text-blue bg-whiteText 
      font-semibold
      rounded-full text-lg px-8 py-2.5 text-center 
      me-2 mb-2 ">
                          {banner?.primaryLinkText}
                        </button></a>
                    </div>}
                </div>
                <div className="w-full flex justify-center pr-[10%]">
                  <img
                    className={` w-[343px] md:w-[360px] lg:w-[496px] xl:w-[631px] 2xl:w-[796px] 
                   w-[343px]`}
                    src={banner?.bannerImage} alt="banner_image"></img>
                </div>
              </div>
            </div>
            <div>
              {!banner?.secondaryLinkText &&
                <a href={banner?.exploreLinkUrl} onClick={onClickExplore} className="absolute ml-[50%] z-10 text-whiteText tracking-widest no-underline pt-[60px] transition-[opacity_0.3s] -translate-x-1/2 bottom-0 desk:bottom-[4.5rem] cursor-pointer hover:opacity-50 "
                  data-testid="explore">{banner?.exploreLinkText}
                  <span className="absolute top-0 left-1/2 w-6 h-6 -ml-3 border-l border-b border-l-whiteText -rotate-45 border-b-whiteText animate-arrow"></span>
                </a>
              }
            </div>
          </div>
        </div>}

      {/* explore content -contains heading and description*/}
      {banner?.exploreScrollContent && banner?.headingPosition === "Center" && (
        <div id={banner?.exploreLinkText?.toLowerCase()} ref={exploreRef} className={`${textPosition(banner?.headingPosition)} py-12 px-4  flex items-center justify-center`}>
          <div className="max-desk:w-[90%] desk:w-3/6 text-darkText font-[MarkPro-Book SKY]">
            <div className="text-[2.5rem] mb-[0.5rem] font-semibold"><span>{banner?.exploreScrollHeading}</span></div>
            <div><DisplayContent value={banner?.exploreScrollContent} heading="" /></div>
          </div>
        </div>
      )}
      {banner?.exploreScrollContent && banner?.headingPosition === "Left" && (
        <div id={banner?.exploreLinkText?.toLowerCase()} ref={exploreRef} className={`${textPosition(banner?.headingPosition)} py-12  flex items-center justify-center`}>
          <div className="desk:flex max-desk:block items-start justify-center desk:w-full text-darkText font-[MarkPro-Light SKY]">
            {(window.location.href.includes("signage") || window.location.href.includes("retail")) ?
              <h3 className="text-[2.5rem] leading-[1.2] font-semibold font-[MarkPro-Book SKY] mb-[2rem] max-tab:w-full desk:w-[38%] px-3">
                {banner?.exploreScrollHeading}</h3>
              :
              <div className="text-[2.6rem] mb-[2rem] max-tab:w-full desk:w-[42%] px-3 font-semibold">
                <span>{banner?.exploreScrollHeading}</span>
              </div>

            }

            <div className="desk:w-[40.5%]  max-tab:w-full px-3">
              <DisplayContent value={banner?.exploreScrollContent} heading="" />
            </div>
          </div>
        </div>
      )}
    </>
  )
}