import { useRef } from 'react';
import { useEffect, useState } from "react";
import { Button } from "../button"
import ReCAPTCHA from 'react-google-recaptcha';
import { Alert } from '../alert';
import { CAPTCHA_KEY } from '../../config/config'

interface Resources {
    key: string;
    value: string;
    required: boolean;
    inpValue?: string;
    checked?: boolean;
}
interface Resource {
    formTitle: string;
    formSubTitle: string;
    resources?: Resources[];
}
interface Props {
    value: Resource;
    onSubmitClick?: any;
    webContent?: boolean;
    captchaErr?: any,
    isCaptchaReq?: boolean
}

// setting form values
const ResourceSet = ({ value, onSubmitClick, webContent, captchaErr, isCaptchaReq }: Props) => {
    const captchaRef = useRef<ReCAPTCHA>();
    const [formdata, setFormdata] = useState<any>()
    const [captchaToken, setCaptchaToken] = useState<any>()
    const [err, setErr] = useState<any>({})   
    useEffect(() => {
        if (value && value?.resources)
            setFormdata(value?.resources)
    }, [value])

    // Applying validation on form fields
    const validateFormVal = (field: any, value: any) => {
        let validateRes = { res: true, msg: "" }
        let regEmail = /^[a-zA-Z0-9.]+@[a-zA-Z0-9]+\.[A-Za-z.]+$/
        
        switch (field?.value) {
            case "Name": {
                if (value === null || value=== undefined || value.length=== 0)
                    validateRes = { res: false, msg: "This field is required" }
                break;

            }
            case "Business Name": {
                if ((value === null || value=== undefined || value.length=== 0) && field?.required)
                    validateRes = { res: false, msg: "This field is required" }
                break;

            }
            case "Contact Name": {
                if (value === null || value=== undefined || value.length=== 0)
                    validateRes = { res: false, msg: "This field is required" }
                break;

            }
            case "Email Address": {
                if (value === null ||  value=== undefined || value.length=== 0 || !value)
                    validateRes = { res: false, msg: "This field is required" }
                else if (!regEmail.test(value)) {
                    
                    validateRes = { res: false, msg: "Please enter valid email address" }
                }
                break;
            }
            case "Phone Number": {
                if (value === null || value=== undefined || value === ""|| value.length=== 0)
                    validateRes = { res: false, msg: "This field is required" }
                break;
            }
            case "Contact Number": {
                if (value === null || value=== undefined || value === ""|| value.length=== 0)
                    validateRes = { res: false, msg: "This field is required" }
                break;
            }
            case "Poster Selection": {
                if (value === null || value=== undefined || value === ""|| value.length=== 0)
                    validateRes = { res: false, msg: "This field is required" }
                break;
            }
            default:
                break;
        }
        return validateRes
    }
    const validateOnBlur = (field:any, value:any) => {
        let validate = validateFormVal(field, value)
        let tmpErr = err

        if (validate?.res) {
            setErr({ ...tmpErr, [field?.value]: { err: false, errMsg: "" } })
        } else {
            setErr({ ...tmpErr, [field?.value]: { err: true, errMsg: validate.msg } })
        }
    }
    const handleChange = (e: any, value: any, check?:string) => {
        let result = [...formdata ? formdata : []]; //<- copy formdata into result
        let validate;
        result = result.map((x) => { //<- use map on result to find element to update using value
            if (x.value === value && !check) {
                validate = validateFormVal(x, e.target.value)
                
                let tmpErr = err
                x = {
                    ...x,
                    inpValue: e.target.value
                }
                if (validate?.res) {
                    setErr({...tmpErr, [value]:{ err: false, errMsg: ""}})
                } else {
                    setErr({...tmpErr, [value]:{ err: true, errMsg: validate.msg}})
                }
            }
            if(check && x.key.includes("posterregistration.posterselection") ){
                if((x.value === value)){
                x = {
                    ...x,
                    inpValue: value,
                    checked:e.target.checked
                }
            }
            if(x.value === "Poster Selection") {
                let val = x.inpValue?x.inpValue:[]
                if(e.target.checked){
                    val.push(value)
                }else if(!e.target.checked && val.includes(value)){
                    const index = val.indexOf(value);
                    if (index > -1) { 
                      val.splice(index, 1);
                    }
                }
                x = {
                    ...x,
                    inpValue: val,
                }
            }
            }
            
            return x;
        });
        setFormdata(result);
    }
    const handleCaptcha = (token:any) => {
        setCaptchaToken(token)
    } 

    
    const handleKey = (e: any, field: string) => {
        
        var theEvent = e 
        let key = theEvent.keyCode || theEvent.which;
        if(key === 37 || key === 38 || key === 39 || key === 40 || key === 8 || key === 46) { // Left / Up / Right / Down Arrow, Backspace, Delete keys
            return;
        }
        key = String.fromCharCode( key );
        if (!/[0-9]/.test(key) && field === "Phone Number") {
            e.preventDefault();
          }
    }
    const onSubmit = (value:any) => {
        let validation = true
        let captchaVal = {key:"false",token:""}
        let submitVal:any = value
        if(value){
            
            let tmpErr = err
            value.forEach(async(item:any) => {
                if((!item.key.includes("help") && !item.key.includes("submit")
                    && !item.key.includes("captcha") && !item.key.includes("option")
                    && !item.key.includes("message"))) {
                        let res = validateFormVal(item, item.inpValue)
                        
                        if (res?.res) {
                            tmpErr = {...tmpErr, [item?.value]:{ err: false, errMsg: ""}}
                        } else {
                            validation = false;
                            tmpErr = {...tmpErr, [item?.value]:{ err: true, errMsg: res.msg}}
                        }
                    }
                if(item.key.includes("captcha") && !item.key.includes("help") &&!item.key.includes("empty")){
                    
                    captchaVal.token = captchaToken
                    captchaVal.key  = "captchaToken"
                    submitVal = [...submitVal, captchaVal]
                    
                }
            })
            setErr(tmpErr)
        }
        if(validation)
        onSubmitClick(submitVal)

        //after submit resetting all values
        if(captchaRef.current)
            captchaRef.current?.reset();
        if((isCaptchaReq && captchaToken && validation) || (!isCaptchaReq && validation)){
            submitVal.forEach((item:any) => {
            if(item.inpValue)
                item.inpValue = '';
            if(item.checked)
                item.checked= false;
        })
    }

    }
    return (
        <div className="">
            <form className="max-tab:w-[90%] max-tab:mx-auto" noValidate>
                {!webContent &&
                // form main heading
                    <h1 className={`font-semibold font-MarkBlack text-darkText text-left text-[2rem] mb-4 mt-4`}>
                        {value?.formTitle}
                    </h1>}
                    <Alert captchaErr={captchaErr}></Alert>
                    {window.location.href.includes("poster-registration") ? 
                    <h3 className='text-[1.125rem] leading-6 font-semibold mb-8'>
                        {value?.formSubTitle}
                    </h3> :
                <div className='text-formSuccess leading-6 text-[1rem] font-MarkLight'>{value?.formSubTitle}</div>}
                {
                    formdata && formdata?.map((item: Resources) => {
                        return (
                            // form input and label appending in view
                            (item.key && !item.key.includes("help") && !item.key.includes("submit")
                                && !item.key.includes("captcha") && !item.key.includes("option")
                                && !item.key.includes("message")  && !item.key.includes("empty")) ? <>
                                <label className="block mb-2 mt-3 text-base font-bold text-gray-900" key={item.value}>
                                    <div className="flex flex-row"><div className="flex-row text-label text-[0.875rem]">
                                        {item.value}</div>
                                        {item.required && <div className="flex-row text-errMsg right-0 top-0 mr-3">*</div>}
                                    </div>
                                    {!item.key.includes("posterselection") && 
                                    <input
                                    type="text"
                                    value={item.inpValue}
                                    onBlur={(e) => validateOnBlur(item,e.target.value)}
                                    className={`border  resource-shadow
                                    text-gray-900 text-sm 
                                    rounded-lg focus:ring-blue-500 
                                    block w-full
                                     p-2.5 dark:bg-gray-700 dark:border-gray-600 
                                     dark:placeholder-gray-400 dark:text-white 
                                     dark:focus:ring-blue-500 dark:focus:border-blueBorder
                                     ${err &&  err[item.value]?.err === true ? 'border-errField bg-errInput focus:border-errField ':'focus:border-blueBorder  border-gray-300 bg-gray-light'}
                                     `}
                                    required
                                    onKeyDown={(e) =>handleKey(e,item.value)}
                                    onChange={(e) => handleChange(e, item.value)}
                                    data-testid={item.value}
                                />}
                                    
                                </label>
                                {err &&  err[item.value]?.err === true  && !item.key.includes("posterselection") &&
                                            <span className='text-errMsg'>{err[item.value].errMsg}</span>
                                   
                                }
                            </>

                                : (item.key && item.key.includes("option") ?<>
                                    <label className={`flex flex-row  mt-3 `} key={item.value}>
                                        <input className="poster" type="checkbox" 
                                        name="todo[1]" value={item.inpValue}
                                        checked={item.checked}
                                        data-testid={item.value}
                                        onClick={(e) => handleChange(e,item.value,"option")}/>
                                        <span className={`px-2 font-bold
                                         ${err &&  err["Poster Selection"]?.err === true ?"text-checkboxErr ":"text-label " }text-[0.875rem]`}>{item.value}</span>
                                    </label>
                                    {err &&  err["Poster Selection"]?.err === true  && item.key.includes("option2") &&
                                            <span className='text-errMsg'>{err["Poster Selection"]?.errMsg}</span>
                                   
                                } 
                                </> :
                                    ((item.key && item.key.includes("message") && !item.key.includes("help")) ?
                                        <label className="block mb-2 mt-3 text-base font-bold text-gray-900" key={item.value}>
                                            <div className="flex flex-row"><div className="text-label text-[0.875rem] flex-row">
                                                {item.value}</div>
                                                {item.required && <div className="flex-row text-errMsg right-0 top-0 mr-3">*</div>}
                                            </div>
                                            <textarea
                                                value={item.inpValue}
                                                className="bg-gray-light border border-gray-300  resource-shadow
                                                text-gray-900 text-sm rounded-lg 
                                                focus:ring-blue-500 focus:border-blueBorder 
                                                block w-full p-2.5 dark:bg-gray-700
                                                 dark:border-gray-600 dark:placeholder-gray-400 
                                                 dark:text-white dark:focus:ring-blue-500 
                                                 dark:focus:border-blueBorder h-[100px]"
                                                required
                                                onChange={(e) => handleChange(e, item.value)}
                                                data-testid={item.value}
                                            />
                                        </label> : (item.key.includes("captcha") && !item.key.includes("help")
                                         && !item.key.includes("empty") && !item.key.includes("captchaToken")) ?
                                            <div>
                                                {/* captcha  */}
                                                <ReCAPTCHA
                                                    ref={captchaRef as any}
                                                    sitekey={CAPTCHA_KEY as string}
                                                    // sitekey="6LfJJCYqAAAAABtNYPmbJOOz8q13Kz-j40oFGZmg"
                                                    theme="light"
                                                    datatype='image'
                                                    onChange={handleCaptcha}
                                                />
                                                 {err &&  err[item.value]?.err === true  && !item.key.includes("posterselection") &&
                                            <span className='text-errMsg'>{err[item.value].errMsg}</span>
                                   
                                }
                                            </div> : <></>

                                    )
                                )
                        )
                    })
                }


                {/* submit button */}
                <div className="relative h-20 pt-6">
                    <Button text="Submit" onClickButton={() => onSubmit(formdata)} />
                </div>

            </form>
        </div>
    )
}

export { ResourceSet }