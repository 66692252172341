/**
 * Hook that interacts with analytcs (e.g. Segment)
 */

import { segment } from './segment';

function useAnalytics() {
    const page = (title: string) => {
        segment.page(title);
    };

    const formSubmit = (
        callBackForm: any,
        formName:string, 
          message:any
    ) => {
        segment.formSubmit(callBackForm, formName,message);
    };

    const downloadClick = (
        fileName: string,
        linkText: string,
        linkUrl: string,
    ) => {
        segment.downloadClick(fileName, linkText, linkUrl )
    }
    return {
        page,
        formSubmit,
        downloadClick
    };
}

export { useAnalytics };
