
import { useLocation } from 'react-router-dom';
import { useState, useEffect, useRef } from "react";
import { useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ContactUs } from '../components/contactUs';
import { getContactUs, getComposePage } from '../graphql/queries/businessComposePage'
import { Banner } from "../components/banner";
import { DisplayContent } from '../components/displayContent';
import { contentTypes, formEmailPayload } from "../config/config"
import { PopUpCard } from '../components/popupCard';
import { ResourceSet } from '../components/resourceSet';
import { useDocumentTitle } from '../config/config';
import { ComponentCard } from '../components/componentCard';
import { ComponentCardCollection } from '../components/componentCardCollection';
import { BlockLoader } from '../components/blockLoader';
import { Sitemap } from '../components/sitemap';
import { sendEmail } from '../services/emailService';
import { useAnalytics } from '../useAnalytics';

const BusinessComposePage = () => {
  let { pathname } = useLocation();
  let { formSubmit, page } = useAnalytics()
  let slug = pathname
  if (slug === "/home")
    slug = '/'
  const [pageLoading, setPageLoading] = useState(false)
  const exploreRef = useRef<HTMLInputElement>(null)
  const contentRef = useRef<HTMLInputElement>(null)
  const { data, loading } = useQuery(getContactUs, {
    variables: {
      usePreview: false,
    }
  });
  const composePage = useQuery(getComposePage, {
    variables: {
      usePreview: false,
      slug: slug
    }
  })

  
  useEffect(() => {
    if (loading || composePage.loading) {
      setPageLoading(true)
    } else if (!loading && !composePage?.loading) {
      setPageLoading(false)
    }

  }, [composePage, loading])

  useDocumentTitle(composePage.data?.BusinessComposePage[0]?.pageTitle)
  useEffect(()=>{
    if(!pageLoading && composePage.data?.BusinessComposePage[0]?.pageTitle)
      page(composePage.data?.BusinessComposePage[0]?.pageTitle);
  },[pageLoading,composePage])
  const [contactInfo, setContactInfo] = useState()
  const [composepageInfo, setComposepAgeInfo] = useState<any>()
  const [businessContent, setBusinessContent] = useState<any>()
  // const [formSubmit, setFormSubmit] = useState<any>()
  const [captchaErr, setCaptchaErr] = useState({ err: false, msg: '' })
  const [formSucess, setFormSuccess] = useState({ success: false, msg: "" })
  useEffect(() => {

    if (data) {
      setContactInfo(data.contactUs[0])
    }

    if (composePage.data) {
      setComposepAgeInfo(composePage.data?.BusinessComposePage[0])
    }
    if (composePage.data?.BusinessComposePage[0]?.content?.businessContent) {
      setBusinessContent(composePage.data?.BusinessComposePage[0]?.content?.businessContent)
    }
  }, [data, composePage])

  const onClickExplore = () => {
    setTimeout(() => {
      const element = document.getElementById("explore") || document.getElementById("scroll");
      const headEle = document.getElementById("header")
      let headHeight = headEle ? headEle?.offsetHeight : 0
      let top = element ? element?.offsetTop - headHeight + 1 : 0
      window.scrollTo({
        behavior: element ? "smooth" : "auto",
        top: top
      });
    }, 100);
  }

  const onFormSubmit = async (value: any, subject: string) => {
    let payload = formEmailPayload(value)
    if (businessContent?.resourceSet?.formTitle.includes("Poster")) {
      payload.subject = "Poster Registration Query"
    } else if (businessContent?.resourceSet?.formTitle.includes("Newsletter")) {
      payload.subject = "Newsletter Query"
    } else if (subject) {
      payload.subject = subject
    }
    let res = await sendEmail(payload);
    if (res.result) {
      setCaptchaErr({ err: false, msg: res.msg })
      if (businessContent?.resourceSet?.formTitle.includes("Poster")) {
        setFormSuccess({ success: true, msg: "Your information was successfully received. Thank you for filling out the form" })
      } else if (businessContent?.resourceSet?.formTitle.includes("Newsletter")
        || subject !== undefined) {
        toast.success('Success: Your request completed successfully.', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
          theme: "light",
          className: "toast-container",
          transition: Bounce,
        });
      }

    } else if (res?.msg?.includes("CAPTCHA")) {
      setCaptchaErr({ err: true, msg: res.msg })
    }
    formSubmit(payload, payload?.subject, res?.result?"Success": res?.msg) //analytics for form submit
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Optional: for smooth scrolling
    });
    // setFormSubmit(value)
  }
  return (
    <>
      {/* seo metadata */}
      {
        businessContent && businessContent?.seo && (
          <Helmet>
            <meta property="og:title" content={businessContent?.seo?.seoTitle} />
            <meta property="og:description" content={businessContent?.seo?.description} />

            <meta name="description" content={businessContent?.seo?.description} />
          </Helmet>
        )
      }

      {/* blockloader */}
      {pageLoading ? <BlockLoader /> : (
        <>
        {businessContent && businessContent?.map((content: any) => {
          return (
            <>
              {/* hero Banner  (contains explore content also)*/}
              {
                content?.contentType === "businessHeroBanner" &&
                <Banner banner={content} onClickExplore={onClickExplore} exploreRef={exploreRef} />
              }

              <div
                id={`${content?.exploreScrollContent ? "contentId" : content?.exploreLinkText?.toLowerCase()}`}
                ref={content?.exploreScrollContent ? contentRef : exploreRef}
              >
                {/* freeform content */}
                {
                  content?.contentType === "freeformContent" && content?.content && (
                    <>
                      <div className='w-full px-4 py-12 mx-auto desk:max-w-[1280px] tab:max-w-[960px] max-tab:max-w-[600px] mb-4'>
                        {content?.heading &&
                          <div className='px-3 w-full text-darkText text-[2.6rem] font-semibold '>
                            {(pathname === "/about-your-bill" || pathname === "/terms-and-conditions-faqs"
                              || pathname === "/faqs" || pathname === "/technical-support"
                            ) ?
                              <h4 className='text-[2rem]'>{content?.heading}</h4> :
                              (pathname === "/channels-and-packages") ? <h2 className='text-[3rem]'>{content?.heading}</h2> :
                                (pathname === "/terms-of-use" || pathname === "/commercial-terms" || pathname === "/privacy-policy") ? <h2 className='text-[2.5rem]'>{content?.heading}</h2> :

                                  (pathname === "/power-reset-steps"
                                    || pathname === "/sky-survivor"
                                  ) ? <h3 className='text-[2rem]'>{content?.heading}</h3> : <h1>{content?.heading}</h1>}
                          </div>
                        }
                        <div className={`desk:px-3 pt-1 pb-2 ${content?.accordionTitle?"desk:w-full":"desk:max-w-[76%]"} max-desk:max-w-full`}>
                          <DisplayContent value={content?.content} heading='' path={pathname} 
                          isAccordion={content?.isAccordion === "true"} accordionTitle={content?.accordionTitle} />
                        </div>
                      </div>
                    </>
                  )
                }

                {/* resource set */}
                {
                  content?.contentType === "resourceSet" && (
                    (content?.formTitle.includes("Poster") && !formSucess.success)
                    || content?.formTitle.includes("Newsletter")) && (
                    <div className='ml-8 mr-10 px-8 my-12 py-3'>
                      <ResourceSet value={content} onSubmitClick={onFormSubmit} webContent={true}
                        captchaErr={captchaErr}
                        isCaptchaReq={content?.formTitle.includes("Newsletter")} />
                    </div>
                  )
                }
                {
                  formSucess.success && content?.formTitle.includes("Poster") &&
                  <div className='mt-[4%] ml-[3%] text-[1.3rem]'><p className='text-formSuccess'>{formSucess.msg}</p></div>
                }
                {/* component section */}
                {/* component card collection */}
                {(content?.contentType === contentTypes.componentCardCollection) && (
                  <ComponentCardCollection
                    card={content?.components}
                    displayTile={content?.displayTile}
                    section={content}
                    slug={slug}
                  />
                )}

                {(content?.contentType !== contentTypes.componentCardCollection) &&
                  content?.components?.map((component: any) => {
                    return (
                      <>
                        {/* popup card collection */}
                        {(component.contentType === contentTypes.popupCard) &&
                          <PopUpCard popupCards={component.cards}
                            isSlider={component?.isSlider}
                            displayTile={content?.displayTile}
                            isCarousel={content?.isCarousel === "Yes"}
                            imageType={component?.imageType}
                            title={content?.componentSectionTitle}
                            subTitle={content?.sectionSubTitle}
                            description={content?.description} />}
                        {/* component card */}
                        {(component.contentType === contentTypes.componentCard) &&
                          <ComponentCard
                            card={component}
                            displayTile={content?.displayTile}
                            isRelatedContent={content?.isRelatedContentSection}
                            title={content?.componentSectionTitle}
                            description={content?.description}
                            subTitle={content?.sectionSubTitle} />
                        }
                      </>
                    )

                  })
                }
                {content?.componentSectionTitle && !content?.contentType &&
                  <><div>
                    <h3 className={`font-semibold text-center text-[2.5rem] my-[2%] pl-2`}>
                      {content?.componentSectionTitle}
                    </h3>
                  </div>
                  {content?.sectionSubTitle && <div>
                        <h6 className={`font-semibold text-center text-[1.5rem] my-[2%] pl-2`} 
                        dangerouslySetInnerHTML={{
                          __html: content?.sectionSubTitle as string,
                        }}>
                       </h6>
                       </div>}
                    {content?.description && <div className='text-center'><DisplayContent value={content?.description} heading='' /></div>}</>}
              </div>
            </>
          )
        })}
        {/* sitemap */}
          {(composepageInfo?.content?.sitemap && composepageInfo?.content?.sitemap?.map((item: any) => {
            return <Sitemap value={item?.menuSections} />
          }))}
        {
           composepageInfo?.contactUsSectionRequired &&
           <>
             {/* horizontal line */}
             <hr className="h-px my-8 bg-slate border-0 dark:bg-slate mx-10"></hr>
 
             {/* contact us and request a call back */}
             <div className='desk:pl-[44px] desk:pr-[66px]'>
               {contactInfo &&
                 <ContactUs value={contactInfo}
                   splitContact={!composepageInfo?.requestACallBackFormRequired}
                   onSubmit={onFormSubmit}
                   captchaErr={captchaErr}
                 />}
             </div>
           </>}
 
         <ToastContainer
           position="bottom-left"
           autoClose={5000}
           hideProgressBar={true}
           closeOnClick
           rtl={false}
           pauseOnFocusLoss
           draggable
           pauseOnHover
           theme="light"
           transition={Bounce}
           toastStyle={{ color: "#287d3d !important" }}
         />
        
         </>
  
      )}
    </>
  )
}
export default BusinessComposePage
export { BusinessComposePage }