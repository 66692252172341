import React, { useState } from 'react';
import cx from 'classnames';

interface AccordionProps {
  title: string | JSX.Element;
  classNames?: string;
  children?: JSX.Element;
  active?: boolean;
  bgGradient?: string;
}

const DownArrow = () => {
  return (
    <svg width="25" height="14" viewBox="0 0 25 14" fill="none" xmlns="http://www.w3.org/2000/svg" className=""><path d="M1 1.00928L13 12.9905L25 1.00928" stroke="#00013A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
  )
}

const UpArrow = () => {
  return (
    <svg width="25" height="14" viewBox="0 0 25 14" fill="none" xmlns="http://www.w3.org/2000/svg" className="rotate-180"><path d="M1 1.00928L13 12.9905L25 1.00928" stroke="#00013A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
  )
}
const Accordion = ({ title, children, classNames, active = false, bgGradient }: AccordionProps) => {
  const [open, setOpenState] = useState(active);
  const toggleAccordian = () => setOpenState(prevState => !prevState);
  return (
    <div>
      <div
        className={`relative ${
          bgGradient === 'Blue Gradient' ? 'sky-blue-gradient text-white' : 'sky-bg-gray-light sky-text-midnight'
        } text-left h-20 px-8 py-4 md:p-4 flex justify-start items-center cursor-pointer ${classNames}`}
        onClick={toggleAccordian}>
        <div className="w-9/10 sky-h6-bold md:sky-h4-bold">{title}</div>
        <button className="h-20 w-10 ipad:w-24 absolute right-0 top-0 sky-h3 md:sky-h4">{open ? <UpArrow /> : <DownArrow />}</button>
      </div>
      <div className={cx('transition-all', { 'h-auto overflow-visible': open, 'h-0 overflow-hidden': !open })}>
        {children}
      </div>
    </div>
  );
};

export { Accordion };
