

export const SegmentECommerceEvents = {
    DOWNLOAD: 'file_download_clicked',
    FORM_SUBMIT: 'sky_business_submit_clicked'
};


class SClient {
    getPageProperties() {
        return {
            title: document.title,            // Page title
            url: window.location.href,        // Full URL of the page
            path: window.location.pathname,   // Path (e.g., /home, /about)
            referrer: document.referrer,      // Referrer (if available)
            pageName: document.title
        };
    }
    track(event: string, properties?: any, options?: any, callback?: any) {
        let segmentFromWindowObject = (window as any).analytics;
        if (!segmentFromWindowObject) {
            return;
        }

        try {
            // Merge the page data with the custom properties
            const finalProperties = {
                ...this.getPageProperties(),  // Include page-related properties
                ...properties,      // Include any custom properties provided
            };

            // Track the event with the merged properties
            segmentFromWindowObject.track(event, finalProperties, options, callback);
        } catch (err) {
            console.error('Error tracking event:', err);
        }
    }

    page(title: string) {
        let segmentFromWindowObject = (window as any).analytics;
        if (!segmentFromWindowObject) {
            console.error('Segment disabled.');
            return;
        }
        try {
            segmentFromWindowObject.page(title);
        } catch (err) {
            console.error(err);
        }
    }

    //GA for Form Submit
    formSubmit(formValue: any, formName:string, message:any ) {
        this.track(SegmentECommerceEvents.FORM_SUBMIT, {
            pageURL:window.location.href,
            e_userName: formValue?.emailAddress?.split('@')[0],
            e_domainName: formValue?.emailAddress?.split('@')[1],
            accountId: formValue?.accountNumber,
            businessName: formValue?.businessName,
            alertMessage: message,
            formName,
        });
    }


     //GA for Download click
     downloadClick(fileName: string, linkText: string, linkUrl:string ) {
        let url = linkUrl
        if(!linkUrl.includes("https:"))
            url = "https:"+url.toString();
            
        this.track(SegmentECommerceEvents.DOWNLOAD, {
            pageURL:window.location.href,
            fileName,
            linkText,
            linkUrl: url,
        });
    }
}

let segment = new SClient();
export { segment };
